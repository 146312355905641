<template>
  <div v-if="state.items.length > 0" class="items--table">
    <div class="mb-4 flex" v-for="item in state.items" :key="item.Id">
      <el-input
        type="text"
        disabled="disabled"
        v-model="item.Name"
        class="mr-2"
      />
      <el-input
        type="text"
        disabled="disabled"
        v-model="item.Price"
        class="price mr-2"
      >
        <template #prefix>
          <span class="ml-2">$</span>
        </template>
      </el-input>
      <el-input
        :min="1"
        v-model="item.Quantity"
        placeholder="Quantity"
        class="quantity"
      />
      <div class="flex">
        <!-- <button
          type="button"
          v-tippy="{
            content: 'Edit',
            animation: 'scale',
            animateFill: false,
            theme: 'tomato',
          }"
          @click="onEdit(item.Id)"
          class="inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-purple-400 hover:text-purple-800 focus:outline-none  focus:ring-purple-500 mx-1"
        >
          <PencilIcon class="flex-shrink-0 h-4 w-4 text-gray-400" />
        </button> -->
        <button
          type="button"
          v-tippy="{
            content: 'Delete',
            animation: 'scale',
            animateFill: false,
            theme: 'tomato',
          }"
          @click="onDelete(item.Id)"
          class="font-small focus:outline-none mx-1 inline-flex items-center rounded-md border border-transparent px-1 py-1 text-sm leading-4 text-red-400 shadow-sm hover:text-red-800 focus:ring-red-500"
        >
          <TrashIcon class="h-4 w-4 flex-shrink-0 text-red-400" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";

import { TrashIcon } from "@heroicons/vue/outline";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  directives: {
    tippy: directive,
  },
  components: {
    TrashIcon,
  },
  setup(props, { emit }) {
    const state = reactive({
      items: props.items,
    });

    const onDelete = (id) => {
      emit("handleDeleteItem", id);
    };

    watch(
      () => state,
      (state) => {
        emit("onItemsChanged", state.items);
      },
      { deep: true }
    );

    return {
      state,
      onDelete,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.items--table {
  margin-top: 20px;
  width: 520px;
}

.quantity {
  width: 150px;
}

.price {
  width: 150px;
}
</style>
