<template>
  <el-select v-model="product" filterable placeholder="Find or add an item">
    <el-option key="new-product" value="NewProduct"
      ><span class="flex"
        ><PlusIcon
          class="space -ml-1 mr-3 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        Create new product</span
      ></el-option
    >
    <div v-if="products.length > 0" class="devider"></div>
    <el-option
      v-for="item in products"
      :key="item.Id"
      :label="item.Name"
      :value="item.Id"
    >
      <div class="flex justify-between">
        <span class="flex flex-row space-x-2">
          <span>{{ item.Name }}</span>
          <span
            v-if="item?.ProductFeatures?.length > 0"
            class="flex flex-row items-center space-x-1"
          >
            <RefreshIcon class="ml-2 h-4 w-4 text-gray-400" />
            <!-- <span class="mx-1"> Every </span>
          <span
            v-for="featureOption in item?.ProductFeatures[0]?.FeatureOptions?.reverse()"
            :key="featureOption.Name"
          >
            {{ featureOption.Name }}
          </span> -->
          </span>
        </span>
        <span class="text-gray-300 hover:border-gray-100 hover:text-gray-500"
          >${{ item.Price }}</span
        >
      </div>
    </el-option>
  </el-select>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "vue";

import { PlusIcon, RefreshIcon } from "@heroicons/vue/outline";
import useProductsRepository from "@/composable/useProductsRepository";

export default {
  components: {
    PlusIcon,
    RefreshIcon,
  },
  setup(_, { emit }) {
    const { products, getProducts } = useProductsRepository();

    const state = reactive({
      product: "",
    });

    let page = 1;
    let limit = 50;

    onMounted(() => {
      getProducts(page, limit);
    });

    watch(
      () => state,
      (state) => {
        if (state.product === "NewProduct") {
          emit("openSlideOver", true);
          state.product = "";
        } else if (state.product) {
          emit("addItem", findByID(state.product));
          state.product = "";
        }
      },
      { deep: true }
    );

    const findByID = (id) => {
      return products.value.find((item) => item.Id === id);
    };

    return {
      ...toRefs(state),
      products,
      findByID,
    };
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
.space {
  margin: 8px 8px 0 0px;
}

.devider {
  border-top: 1px solid #ebebeb;
  margin: 8px 0;
}
</style>
