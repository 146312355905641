<template>
  <ItemSelect
    :products="state.items"
    @openSlideOver="openSlide"
    @addItem="addItem"
  />
  <ItemTable
    v-if="state.items.length > 0"
    :items="state.items"
    @onItemsChanged="onItemsChanged"
    @handleDeleteItem="onDeleteItem"
  />
  <CreateNewProduct
    :show="state.open"
    @onCreateProduct="productCreated"
    @onClose="closeSlide"
  />
</template>

<script>
import { reactive, toRefs, watch, inject } from "vue";

import ItemSelect from "./_select";
import ItemTable from "./_table";
import CreateNewProduct from "./_newProduct.vue";

export default {
  components: {
    ItemTable,
    ItemSelect,
    CreateNewProduct,
  },
  setup(_, { emit }) {
    const newInvoice = inject("new-invoice-created");

    const state = reactive({
      open: false,
      productCreated: false,
      items: [],
    });

    const openSlide = () => {
      state.open = true;
    };

    const closeSlide = () => {
      state.open = false;
    };

    const productCreated = (product) => {
      state.open = false;
      product.Quantity = 1;
      state.items.push(product);
    };

    const addItem = (item) => {
      const existingItem = state.items.find((i) => i.Id === item.Id);
      if (existingItem) {
        existingItem.Quantity += 1;
      } else {
        item.Quantity = 1;
        state.items.push(item);
      }

      emit("selectedItems", state.items);
    };

    const onDeleteItem = (id) => {
      state.items = state.items.filter((item) => item.Id !== id);
      emit("selectedItems", state.items);
    };

    const onItemsChanged = (items) => {
      emit("selectedItems", items);
    };

    // clean table when new invoice is created
    watch(
      () => newInvoice,
      (newInvoice) => {
        if (newInvoice) {
          state.items = [];
        }
      },
      { deep: true }
    );

    return {
      state,
      ...toRefs(state),
      addItem,
      openSlide,
      closeSlide,
      onDeleteItem,
      productCreated,
      onItemsChanged,
    };
  },
};
</script>

<style lang="scss" scoped></style>
